import { useTranslation } from 'react-i18next'
import {
  Badge,
  ButtonTextV2,
  Filter,
  ProgressBar,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  Tooltip
} from '@percent/lemonade'
import { formatMoney } from '@percent/utility'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { FundraisersTableProps } from './FundraisersTable.types'
import { config } from '@percent/partner-dashboard/config'
import styles from './FundraisersTable.module.scss'
import { useStatusFilter } from '../hooks/useStatusFilter'

export function FundraisersTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams
}: Readonly<FundraisersTableProps>) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  const columns = [
    { id: t('table.header.name'), props: { width: '20%' } },
    { id: t('table.header.nonprofit'), props: { width: '25%' } },
    { id: t('table.header.owner') },
    { id: t('table.header.goal'), props: { width: '12%' } },
    { id: t('table.header.status'), props: { width: '8%' } },
    { id: t('table.header.actions'), props: { width: '5%' } }
  ]

  const { statusOptions, selectedStatus, onStatusChange } = useStatusFilter({ queryParams, setQueryParams })

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <TableProvider
      columns={columns}
      totalResults={totalResults}
      isLoading={isLoading || !data}
      illustration="no-results"
      emptyTableTitle={t('typography.noListTitle', { listType: 'fundraisers' })}
      emptyTableDescription={t('typography.noListDescription', { listType: 'fundraisers' })}
    >
      <Filter
        label={t('table.status')}
        options={statusOptions}
        selectedValue={selectedStatus}
        onChange={onStatusChange}
        testId="fundraiser-status"
      />
      <Table>
        <TableBody>
          {data?.map(fundraiser => (
            <TableRow key={fundraiser.id}>
              <TableCell>
                <Tooltip content={fundraiser.page.title}>
                  <a href={`${config.urls.fundraiserUrl}/${fundraiser.id}`} target="_blank" rel="noopener noreferrer">
                    <span className={styles.fundraisersNameCell}>{fundraiser.page.title}</span>
                  </a>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip content={fundraiser.organisation.name}>
                  <span className={styles.fundraisersTableCell}>{fundraiser.organisation.name}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip content={fundraiser.organiser.email}>
                  <div className={styles.donorWrapper}>
                    <span>{fundraiser.organiser.name}</span>
                    <span className={styles.email}>{fundraiser.organiser.email}</span>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                {formatMoney(fundraiser.donationTotal, { locale, hideDecimalsIfZero: true })}/
                {formatMoney(fundraiser.campaign.goal, { locale, hideDecimalsIfZero: true })}
                <ProgressBar
                  progressPercentage={Math.min(
                    (fundraiser.donationTotal.amount / fundraiser.campaign.goal.amount) * 100,
                    100
                  )}
                  backgroundColorComplete="info400"
                  backgroundColorIncomplete="info400"
                  shadeColor="gray90"
                  sizeVariant="x-small"
                />
              </TableCell>
              <TableCell>
                {fundraiser.status === 'published' ? (
                  <Badge variant="informative">{t('status.open')}</Badge>
                ) : (
                  <Badge variant="default">{t(`status.${fundraiser.status}`)}</Badge>
                )}
              </TableCell>
              <TableCell>
                {' '}
                <ButtonTextV2 disabled>{t('button.more')}</ButtonTextV2>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        totalResultsText={t('table.results', { count: totalResults || undefined })}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </TableProvider>
  )
}
