import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'
import { VolunteeringTable } from './VolunteeringTable/VolunteeringTable'
import { OpportunityStatus } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'

const initialFilters = {
  parentId: 'null',
  status: [OpportunityStatus.ACTIVE]
}

export function VolunteeringContainer() {
  const { workplaceGivingService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams(
    {
      query: StringParam,
      parentId: StringParam,
      status: ArrayParam
    },
    {}
  )

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getVolunteeringOpportunitiesList,
    {
      ...queryParams,
      ...initialFilters
    }
  )

  useDidMountEffect(() => {
    if (!queryParams.parentId && !queryParams.status) {
      query({ ...queryParams, parentId: 'null', status: [OpportunityStatus.ACTIVE] })
      setQueryParams?.({
        ...queryParams,
        ...initialFilters
      })
    } else {
      query({ ...queryParams, parentId: 'null' })
    }
  }, [queryParams, query])

  useDidMountEffect(() => {
    setQueryParams?.({
      ...queryParams,
      ...initialFilters
    })
  }, [])

  return (
    <VolunteeringTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
